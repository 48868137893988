import React from 'react';
import { Spinner } from 'react-bootstrap';
import { strings } from '~utils/strings';
import './LoadingSpinner.react.scss';

type LoadingSpinnerProps = {
  containerClassNames?: string;
  spinnerClassNames?: string;
};

const LoadingSpinner = ({
  containerClassNames,
  spinnerClassNames,
}: LoadingSpinnerProps): JSX.Element => {
  return (
    <div
      className={`h-50 mt-4 d-flex justify-content-center align-items-center ${containerClassNames}`}
    >
      <Spinner animation="border" role="status" className={`loading-spinner ${spinnerClassNames}`}>
        <span className="sr-only">{`${strings.general.Loading}${strings.punctuation.ellipsis}`}</span>
      </Spinner>
    </div>
  );
};

export default LoadingSpinner;
