import * as React from 'react';
import { COLORS } from '~utils/styling';

function CloseIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill={COLORS.CORE.GRAY}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10 10"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.01 9.1a.6.6 0 10.85-.84L5.58 4.98l3.26-3.26A.6.6 0 108 .87L4.73 4.13 1.23.63a.6.6 0 00-.85.84l3.5 3.5L.37 8.52a.6.6 0 00.84.85l3.53-3.53L8.01 9.1z"
      />
    </svg>
  );
}

export default CloseIcon;
