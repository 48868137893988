import React, { useEffect } from 'react';
import { Row, TableInstance } from 'react-table';
import { TriggerEvent } from 'react-contexify';
import clsx from 'clsx';

import { SelectedFilters } from '~views/organization/efficiency/CumulativeCosts/Costs.types.react';

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner.react';

import { strings } from '~utils/strings';
import { ColumnHeaderText } from '~utils/texts.react';

import './Table.react.scss';

type TableProps = {
  tableInstance: TableInstance<any>;
  pagination?: React.ReactNode;
  contextMenu?: React.ReactNode;
  loaded?: boolean;
  onDataFetched?: (option?: SelectedFilters, removedValue?: boolean) => void;
  onFlatRowsSelected?: React.Dispatch<React.SetStateAction<Row<any>[]>>;
  onItemSelected?: React.Dispatch<React.SetStateAction<any | null>>;
  onContextMenuClicked?: (e: TriggerEvent, item: any) => void;
  setSelectedFlatRows?: React.Dispatch<React.SetStateAction<Row<any>[]>>;
  caption: string;
  hiddenColumns?: string[];
  className?: string;
  dataCy?: string;
};

const Table = ({
  tableInstance,
  pagination,
  contextMenu,
  loaded,
  onContextMenuClicked,
  onDataFetched,
  onFlatRowsSelected,
  onItemSelected,
  setSelectedFlatRows,
  hiddenColumns,
  caption,
  className,
  dataCy,
}: TableProps) => {
  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    selectedFlatRows,
    setPageSize,
    setHiddenColumns,
    state: { pageIndex, pageSize, filters, sortBy },
  } = tableInstance;

  useEffect(() => {
    setPageSize && setPageSize(tableInstance.state.pageSize);
  }, [tableInstance.state.pageSize]);

  useEffect(() => {
    setSelectedFlatRows && setSelectedFlatRows(selectedFlatRows);
  }, [selectedFlatRows]);

  useEffect(() => {
    if (hiddenColumns) setHiddenColumns(hiddenColumns);
  }, [hiddenColumns]);

  useEffect(() => {
    if (onFlatRowsSelected) {
      onFlatRowsSelected(selectedFlatRows);
    }
  }, [selectedFlatRows]);

  useEffect(() => {
    if (onDataFetched) onDataFetched();
  }, [pageIndex, sortBy, pageSize, filters]);

  if (loaded !== null && loaded !== undefined && !loaded) return <LoadingSpinner />;

  return (
    <div className={`custom-table ${className || ''}`}>
      <table {...getTableProps()} aria-label={caption} data-cy={dataCy || 'fw-table'}>
        <thead role={strings.ariaRoles.rowgroup}>
          {headerGroups.map((headerGroup) => (
            // Apply the header row props
            <tr {...headerGroup.getHeaderGroupProps()} role={strings.ariaRoles.row}>
              {headerGroup.headers.map((column) => {
                const isSelection = column.id === strings.general.selection;
                return (
                  <th
                    {...column.getHeaderProps()}
                    className={clsx({ 'select-header': isSelection }, ColumnHeaderText())}
                    role="presentation"
                  >
                    <div {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {isSelection ? <span className="sr-only">Select all rows</span> : null}
                      <span>{column.render('Header')}</span>
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <i className="fas fa-arrow-down" />
                          ) : (
                            <i className="fas fa-arrow-up" />
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                    <div>{column.canFilter && column.Filter ? column.render('Filter') : null}</div>
                    {!isSelection ? (
                      <div {...column.getResizerProps()} className="draggable-column" />
                    ) : null}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {(page || rows).map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  // If we are clicking on anything but the row, return
                  const target = e.target as HTMLElement;
                  if (target?.previousElementSibling) {
                    return;
                  }
                  if (onItemSelected) onItemSelected(row.original);
                }}
                className="pointer"
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      onContextMenu={
                        onContextMenuClicked
                          ? (e) => onContextMenuClicked(e, row.original)
                          : () => {}
                      }
                      {...cell.getCellProps([
                        {
                          className: `${cell.column?.className} ${
                            cell.column?.id === strings.general.selection ? 'select-column' : ''
                          }`,
                          style: cell.column?.style,
                        },
                      ])}
                    >
                      <div className="cell-container">{cell.render('Cell')}</div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {pagination}
      {contextMenu}
    </div>
  );
};

export default Table;
